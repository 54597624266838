import React, { useEffect, useState } from "react";
import Reveal from "../motion/Reveal";
import { httpReauest } from "../../utils/httprequest";

const NavDashboard = () => {
  const [data, setData] = useState();
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const user = JSON.parse(localStorage.getItem("user"));
    const newData = await httpReauest(
      "GET",
      "/user/" + user?._id,
      {},
      { "x-access-token": user?.token }
    );

    setData(newData.data.data);
  }
  return (
    <div class="col-lg-3 col-md-12">
      <Reveal>
        <div class="dash__box dash__box--bg-white dash__box--shadow dash__box--w rounded-[15px]">
          <div class="dash__pad-1">
            <ul class="dash__w-list">
              <li>
                <div class="dash__w-wrap">
                  <span class="dash__w-icon dash__w-icon-style-1">
                    <i class="fas fa-cart-arrow-down"></i>
                  </span>

                  <span class="dash__w-text">{data?.orders?.length}</span>

                  <span class="dash__w-name">سفارش های ثبت شده</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default NavDashboard;
