import React from "react";
import Reveal from "../motion/Reveal";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import HelpAnimation from "../../lottie/help.json";
import { SrviceInfo } from "../../pages/ServiceDetails";

const HelpYou = () => {
  return (
    <div class="u-s-p-y-60">
      <div class="section__intro u-s-m-b-40">
        <div class="container">
          <div style={{ justifyContent: "center" }} class="row">
            <Reveal>
              <div class="section__text-wrap">
                <h1 class="section__heading u-c-secondary u-s-m-b-12">
                  محصولات پر طرفدار
                </h1>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
      <div class="section__content">
        <div class="container">
          <div className="row justify-between items-center">
            <Link
              to={"/products?category=65bf25b042bd93ebfa65a11e"}
              class="col-md-4 p-0"
            >
              <Reveal>
                <img src="/s.png" className="w-[70%] mx-auto" />
              </Reveal>
              <Reveal>
                <h3 className="text-center text-black text-[22px] font-bold mt-8">
                  اسنک/وافل/گریل ساز
                </h3>
                <p className="text-center ">1 محصول</p>
              </Reveal>
            </Link>
            <Link
              to={
                "/products?balance&category=65bf25a242bd93ebfa65a11d&discounted&max_price&min_price&subCategory"
              }
              class="col-md-4 p-0"
            >
              <Reveal>
                <img src="/s1.png" className="w-[70%] mx-auto" />
              </Reveal>
              <Reveal>
                <h3 className="text-center text-black text-[22px] font-bold mt-8">
                  خرد کن
                </h3>
                <p className="text-center">1 محصول</p>
              </Reveal>
            </Link>
            <Link
              to={
                "/products?balance=&category=65dec44e8e5c65f0600ad60d&discounted=&max_price=&min_price=&subCategory="
              }
              class="col-md-4 p-0"
            >
              <Reveal>
                <img src="/s2.png" className="w-[70%] mx-auto" />
              </Reveal>
              <Reveal>
                <h3 className="text-center text-black text-[22px] font-bold mt-8">
                  همزن
                </h3>
                <p className="text-center">1 محصول</p>
              </Reveal>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpYou;
