import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

const Reveal = ({ width, children, head, height }) => {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });

  const mainControls = useAnimation();
  const slideControls = useAnimation();

  useEffect(() => {
    if (inView) {
      mainControls.start("visible");
      slideControls.start("visible");
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      style={{
        position: "relative",
        overflow: head ? "visible" : "hidden",
        width: width,
        height: height && "100%",
      }}
    >
      <motion.div
        variants={{
          hidden: { opacity: 0, y: head ? -75 : 75 },
          visible: { opacity: 1, y: 0 },
        }}
        style={{
          height: height && "100%",
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.5, delay: 0.25 }}
      >
        {children}
      </motion.div>
      {!head && (
        <motion.div
          variants={{ hidden: { right: 0 }, visible: { right: "100%" } }}
          initial="hidden"
          animate={slideControls}
          transition={{ duration: 0.5, ease: "easeIn" }}
          style={{
            position: "absolute",
            top: 4,
            bottom: 4,
            left: 0,
            right: 0,
            background: "var(--brand)",
            zIndex: 20,
          }}
        />
      )}
    </div>
  );
};

export default Reveal;
