import React, { useEffect } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Reveal from "../components/motion/Reveal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { httpReauest } from "../utils/httprequest";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Signin = () => {
  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      password: Yup.string().required("الزامی است"),
      phone: Yup.string()
        .min(11, "شماره موبایل صحیح نیست")
        .max(11, "شماره موبایل صحیح نیست")
        .required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: { phone: "", password: "" },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      await httpReauest("POST", "/user/signin", values, {})
        .then((data) => {
          toast.success("اطلاعات با موفقیت ثبت شد");
          formik.resetForm();
          window.location.href = window.origin + "/dashboard";
          localStorage.setItem("user", JSON.stringify(data.data?.data));
        })
        .catch((data) => {
          toast.error(data?.response?.data?.message);
          formik.resetForm();
        });
    },
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user?.token) {
      window.location.href = window.origin + "/dashboard";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{"ساراپاشا - ورود"}</title>
        <meta name="og:title" content={"ساراپاشا - ورود"} />
      </Helmet>
      <Header />
      <div style={{ background: "#eee" }} class="app-content">
        <div class="u-s-p-y-60">
          <div class="section__intro u-s-m-b-60">
            <div class="container">
              <div class="row row--center">
                <Reveal>
                  <div class="col-lg-12">
                    <div class="section__text-wrap">
                      <h1 class="section__heading u-c-secondary">
                        ورود به پروفایل
                      </h1>
                    </div>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>

          <div class="section__content">
            <div class="container">
              <div class="row row--center">
                <div class="col-lg-6 col-md-8 u-s-m-b-30">
                  <Reveal head>
                    <div
                      style={{ background: "#fff", borderRadius: "25px" }}
                      class="l-f-o"
                    >
                      <div class="l-f-o__pad-box">
                        <h1 class="gl-h1">اطلاعات خود را وارد کنید</h1>
                        <form
                          onSubmit={formik.handleSubmit}
                          class="l-f-o__form"
                        >
                          <div class="u-s-m-b-30">
                            <label class="gl-label" for="phone">
                              شماره همراه *
                            </label>

                            <input
                              class="input-text input-text--primary-style"
                              type="text"
                              id="phone"
                              name="phone"
                              value={formik.values.phone}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="شماره همراه"
                            />
                            {formik.errors.phone && formik.touched.phone && (
                              <small
                                className="text-danger"
                                style={{ color: "red" }}
                              >
                                {formik.errors.phone}
                              </small>
                            )}
                          </div>
                          <div class="u-s-m-b-30">
                            <label class="gl-label" for="password">
                              رمز *
                            </label>

                            <input
                              class="input-text input-text--primary-style"
                              type="password"
                              id="password"
                              name="password"
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="رمز"
                            />
                            {formik.errors.password &&
                              formik.touched.password && (
                                <small
                                  className="text-danger"
                                  style={{ color: "red" }}
                                >
                                  {formik.errors.password}
                                </small>
                              )}
                          </div>
                          <div class="u-s-m-b-15">
                            <button
                              class="btn btn--e-transparent-brand-b-2"
                              type="submit"
                            >
                              ارسال
                            </button>
                          </div>
                          <Link class="gl-link" to="/signup">
                            هنوز ثبت نام نکردی؟
                          </Link>
                        </form>
                      </div>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Signin;
