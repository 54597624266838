import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Reveal from "../motion/Reveal";
import { SrviceInfo } from "../../pages/ServiceDetails";
import { CDN_BASE_URL } from "../../config";
import { CartContext } from "../../App";
import { httpReauest } from "../../utils/httprequest";

const Header = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [allPrice, setallPrice] = useState(0);
  const [category, setCategory] = useState();
  const [open, setopen] = useState(false);

  const { cart, setCart } = useContext(CartContext);

  useEffect(() => {
    renderData();
  }, [cart]);

  function renderData() {
    let n = 0;
    cart?.map((e) => {
      n = n + (e.special_price ? e.special_price : e.price) * e.count;
    });
    setallPrice(n);
  }

  function handleDelete(id) {
    const newCart = cart.filter((e) => e._id != id);
    setCart(newCart);
  }

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const newData = await httpReauest("GET", "/product/category", {}, {});

    setCategory(newData.data.data);
  }

  return (
    <>
      <div className="fixed bottom-0 right-0 lg:hidden flex py-1 px-1 bg-white z-50 w-full ">
        <span className="col-3 p-2">
          <Link
            to={"/products"}
            className="flex flex-col items-center justify-center text-black"
          >
            <i className="fa-solid fa-shop "></i>
            <p className="font-bold">فروشگاه</p>
          </Link>
        </span>
        <span className="col-3 p-2">
          <Link
            to={"/products"}
            className="flex flex-col items-center justify-center text-black"
          >
            <i className="fa-solid fa-phone"></i>
            <p className="font-bold">تماس با ما</p>
          </Link>
        </span>
        <span className="col-3 p-2">
          <Link
            to={"/#footer"}
            className="flex flex-col items-center justify-center text-black"
          >
            <i className="fa-solid fa-circle-check"></i>
            <p className="font-bold">اعتماد شما</p>
          </Link>
        </span>
        <span className="col-3 p-2">
          <Link
            to={"/cart"}
            className="flex flex-col items-center justify-center text-black"
          >
            <i class="fa-solid fa-cart-shopping"></i>
            <p className="font-bold">سبد خرید</p>
            <span class="absolute bg-[--brand] rounded-full w-[20px] h-[20px] flex items-center justify-center text-white left-4 top-2">
              {cart?.length}
            </span>
          </Link>
        </span>
      </div>
      <header class="header--style-1">
        <Reveal head>
          <nav class="primary-nav primary-nav-wrapper--border">
            <div class="container">
              <div class="primary-nav">
                <Link class="main-logo" href="/">
                  <img
                    src="/images/logo/logo-1.png"
                    className="w-[80px]"
                    alt=""
                  />
                </Link>

                <form class="main-form">
                  <label for="main-search"></label>

                  <input
                    class="input-text input-text--border-radius input-text--style-1"
                    type="text"
                    id="main-search"
                    placeholder="جستجو در محصولات"
                  />

                  <button
                    class="btn btn--icon fa-solid fa-search main-search-button"
                    type="submit"
                  ></button>
                </form>

                <div class="menu-init" id="navigation">
                  <div class="ah-lg-mode">
                    <span class="ah-close">✕ Close</span>

                    <ul class="ah-list ah-list--design1 ah-list--link-color-secondary">
                      <li
                        class="has-dropdown"
                        data-tooltip="tooltip"
                        data-placement="left"
                        title="Account"
                      >
                        <Link to="/cart">
                          <i class="fa-solid fa-cart-shopping"></i>
                          <span class="total-item-round">{cart?.length}</span>
                        </Link>

                        <span class="js-menu-toggle"></span>
                        <div class="mini-cart">
                          <div class="mini-product-container gl-scroll u-s-m-b-15">
                            {cart?.length ? (
                              cart?.map((p) => {
                                return (
                                  <div class="card-mini-product">
                                    <div class="mini-product">
                                      <div class="mini-product__image-wrapper">
                                        <a
                                          class="mini-product__link"
                                          href="product-detail.html"
                                        >
                                          <img
                                            class="u-img-fluid"
                                            src={CDN_BASE_URL + p.img}
                                            alt=""
                                          />
                                        </a>
                                      </div>
                                      <div class="mini-product__info-wrapper">
                                        <span class="mini-product__category">
                                          <a href="shop-side-version-2.html">
                                            {p?.category?.name}
                                          </a>
                                        </span>

                                        <span class="mini-product__name">
                                          <a href="product-detail.html">
                                            {p.name}
                                          </a>
                                        </span>

                                        <span class="mini-product__price">
                                          {p.special_price
                                            ? p.special_price.toLocaleString()
                                            : p.price.toLocaleString()}{" "}
                                          تومان
                                        </span>
                                        <span class="mini-product__quantity">
                                          {p.count}x
                                        </span>
                                      </div>
                                    </div>

                                    <a
                                      onClick={() => handleDelete(p._id)}
                                      class="mini-product__delete-link fa-regular fa-trash-alt"
                                    ></a>
                                  </div>
                                );
                              })
                            ) : (
                              <h3 className="text-center text-[25px] text-black">
                                سبد خالی است
                              </h3>
                            )}
                          </div>

                          <div class="mini-product-stat">
                            <div class="mini-total">
                              <span class="subtotal-text">مجموع</span>

                              <span class="subtotal-value">
                                {allPrice.toLocaleString()}تومان
                              </span>
                            </div>
                            <div class="mini-action">
                              <Link
                                class="mini-link btn--e-transparent-secondary-b-2"
                                to="/cart"
                              >
                                مشاهده سبد خرید
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li
                        class="has-dropdown"
                        data-tooltip="tooltip"
                        data-placement="left"
                        title="Account"
                      >
                        <a>
                          <i class="fa-solid fa-user-circle"></i>
                        </a>

                        <span class="js-menu-toggle"></span>
                        <ul style={{ width: "120px" }}>
                          <li>
                            <Link to="/dashboard">
                              <i class="fa-solid fa-user-circle u-s-m-l-6"></i>

                              <span>پروفایل</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/signup">
                              <i class="fa-solid fa-user-plus u-s-m-l-6"></i>

                              <span>ثبت نام</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/signin">
                              <i class="fa-solid fa-lock u-s-m-l-6"></i>

                              <span>ورود</span>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li
                        data-tooltip="tooltip"
                        data-placement="left"
                        title="Contact"
                      >
                        <a href="tel:+0900901904">
                          <i class="fa-solid fa-phone-volume"></i>
                        </a>
                      </li>
                      <li
                        data-tooltip="tooltip"
                        data-placement="left"
                        title="Mail"
                      >
                        <a href="mailto:contact@domain.com">
                          <i class="fa-solid fa-envelope"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          <nav class="primary-nav primary-nav-wrapper--border">
            <div class="container">
              <div class="primary-nav">
                <div class="menu-init" id="navigation1">
                  <button
                    class="btn btn--icon toggle-mega-text toggle-button"
                    type="button"
                    onClick={() => setShow2(true)}
                  >
                    دسته بندی محصولات
                  </button>

                  <div class="ah-lg-mode">
                    <span class="ah-close">✕ Close</span>

                    <ul class="ah-list">
                      <li class="has-dropdown has-dropdown--ul-left-10">
                        <a class="cursor-pointer px-4  items-center gap-2  mega-text">
                          {" "}
                          دسته بندی محصولات{" "}
                          <i className="fa-solid mt-1 fa-angle-down"></i>
                        </a>
                        <ul
                          className="grid grid-cols-3"
                          style={{ width: "35vw" }}
                        >
                          {category?.map((cat) => {
                            return (
                              <li>
                                <Link to={"/products?category=" + cat._id}>
                                  {cat.name}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="ah-lg-mode">
                  <span class="ah-close">✕ Close</span>

                  <ul class="ah-list ah-list--design2 ah-list--link-color-secondary">
                    <li>
                      <Link to="/">خانه</Link>
                    </li>
                    <li class="has-dropdown has-dropdown--ul-left-10">
                      <Link to="/products">فروشگاه</Link>
                    </li>
                    <li>
                      <Link to="/about">درباره ما</Link>
                    </li>
                    <li>
                      <Link to="/#footer">ارتباط با ما</Link>
                    </li>
                  </ul>
                </div>

                <div class="menu-init" id="navigation">
                  <Link
                    className="lg:hidden"
                    style={{ marginLeft: "42px" }}
                    to="/cart"
                  >
                    <i class="fa-solid fa-cart-shopping"></i>
                    <span class="total-item-round">{cart?.length}</span>
                  </Link>
                  <button
                    class="btn btn--icon text-black toggle-button toggle-button--secondary fa-solid fa-align-justify"
                    type="button"
                    onClick={() => setShow(true)}
                  ></button>

                  <div
                    className={`${
                      !show && "left-[-100%]"
                    } fixed left-0 top-0 h-full w-[250px] bg-white z-10 duration-500`}
                  >
                    <div className="w-full border-b p-1">
                      <span onClick={() => setShow(false)} class="ah-close">
                        ✕
                      </span>
                    </div>
                    <div className="w-full">
                      <ul class="ah-list ah-list--design2 ah-list--link-color-secondary w-full">
                        <li>
                          <Link to="/">خانه</Link>
                        </li>
                        <li>
                          <Link to="/products">فروشگاه</Link>
                        </li>

                        <li>
                          <Link to="/signup">ثبت نام</Link>
                        </li>
                        <li>
                          <Link to="/signin">ورود</Link>
                        </li>
                        <li>
                          <Link to="/about">درباره ما</Link>
                        </li>
                        <li>
                          <Link to="#footer">ارتباط با ما</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className={`${
                      !show2 && "left-[-100%]"
                    } fixed left-0 top-0 h-full w-[250px] bg-white z-10 duration-500 overflow-y-scroll`}
                  >
                    <div className="w-full border-b p-1">
                      <span onClick={() => setShow2(false)} class="ah-close">
                        ✕
                      </span>
                    </div>
                    <div className="w-full">
                      <ul class="ah-list ah-list--design2 ah-list--link-color-secondary w-full">
                        {category?.map((cat) => {
                          return (
                            <li>
                              <Link to={"/products?category=" + cat._id}>
                                {cat.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </Reveal>
      </header>
    </>
  );
};

export default Header;
