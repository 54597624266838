import React from "react";
import Reveal from "../motion/Reveal";

const Dontmiss = () => {
  return (
    <div class="u-s-p-y-60 bg-[#eee]">
      <div class="section__content">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6 u-s-m-b-30">
              <div class="service u-h-100">
                <div class="service__icon">
                  <i class="fa-solid fa-truck"></i>
                </div>
                <div class="service__info-wrap">
                  <span class="service__info-text-1">ارسال به سراسر کشور</span>

                  <span class="service__info-text-2">
                    از هر جا که هستی میتونی سفارش بدی و ما در عرض چند روز به
                    دستت میرسونیم
                  </span>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 u-s-m-b-30">
              <div class="service u-h-100">
                <div class="service__icon">
                  <i class="fa-solid fa-check-circle"></i>
                </div>
                <div class="service__info-wrap">
                  <span class="service__info-text-1">پرداخت امن</span>

                  <span class="service__info-text-2">
                    پرداخت های ما با استفاده از زرین پال انجام میشه
                  </span>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 u-s-m-b-30">
              <div class="service u-h-100">
                <div class="service__icon">
                  <i class="fa-solid fa-headphones-alt"></i>
                </div>
                <div class="service__info-wrap">
                  <span class="service__info-text-1">
                    پاسخگو سوالات شما هستیم
                  </span>

                  <span class="service__info-text-2">
                    هر موقع سوالی داشتید میتونید بپرسید در اولین فرصت جواب داده
                    میشه
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dontmiss;
