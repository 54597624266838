import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import NavDashboard from "../components/layout/NavDashboard";
import { useParams } from "react-router-dom";
import { httpReauest } from "../utils/httprequest";
import moment from "moment";
import { CDN_BASE_URL } from "../config";
import { Helmet } from "react-helmet";

const OrderDetails = () => {
  const { id } = useParams();

  const [data, setData] = useState();
  console.log(data);
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const user = JSON.parse(localStorage.getItem("user"));
    const newData = await httpReauest(
      "GET",
      "/order/" + id,
      {},
      { "x-access-token": user?.token }
    );

    setData(newData.data.data);
  }

  return (
    <div>
      <Helmet>
        <title>{"ساراپاشا - اطلاعات سفارش"}</title>
        <meta name="og:title" content={"ساراپاشا - اطلاعات سفارش"} />
      </Helmet>
      <Header />
      <div className="app-content bg-[#eee]">
        <div class="u-s-p-y-60">
          <div class="section__content">
            <div class="dash">
              <div class="container">
                <div class="row">
                  <NavDashboard />
                  <div class="col-lg-9 col-md-12">
                    <h1 class="dash__h1 u-s-m-b-30">مشخصات سفارش</h1>
                    <div class="dash__box dash__box--shadow dash__box--radius dash__box--bg-white u-s-m-b-30">
                      <div class="dash__pad-2">
                        <div class="dash-l-r">
                          <div>
                            <div class="manage-o__text-2 u-c-secondary">
                              سفارش {data?._id}#
                            </div>
                            <div class="manage-o__text u-c-silver">
                              در تاریخ{" "}
                              {moment(data?.createdAt).format("YYYY/MM/DD")} در
                              ساعت {moment(data?.createdAt).format("HH:mm")} ثبت
                              شده
                            </div>
                          </div>
                          <div>
                            <div class="manage-o__text-2 u-c-silver">
                              هزینه کل:
                              <span class="manage-o__text-2 u-c-secondary mr-1">
                                {data?.price?.toLocaleString()} تومان
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="dash__box dash__box--shadow dash__box--radius dash__box--bg-white u-s-m-b-30">
                      <div class="dash__pad-2">
                        <div class="manage-o">
                          <div class="dash-l-r">
                            <div class="manage-o__text u-c-secondary">
                              تاریخ تحویل :{" "}
                              {data?.reciveData
                                ? data?.reciveData
                                : "هنوز سفارش در حال اماده سازی هست"}
                            </div>
                          </div>
                          <div class="manage-o__timeline">
                            <div class="timeline-row">
                              <div class="col-lg-4 u-s-m-b-30">
                                <div class="timeline-step">
                                  <div
                                    class={`timeline-l-i  timeline-l-i--finish`}
                                  >
                                    <span class="timeline-circle"></span>
                                  </div>

                                  <span class="timeline-text">
                                    در حال اماده سازی
                                  </span>
                                </div>
                              </div>
                              <div class="col-lg-4 u-s-m-b-30">
                                <div class="timeline-step">
                                  <div
                                    class={`timeline-l-i ${
                                      data?.status == "ارسال شده"
                                        ? "timeline-l-i--finish"
                                        : data?.status == "دریافت شده"
                                        ? "timeline-l-i--finish"
                                        : ""
                                    } `}
                                  >
                                    <span class="timeline-circle"></span>
                                  </div>

                                  <span class="timeline-text">ارسال شده</span>
                                </div>
                              </div>
                              <div class="col-lg-4 u-s-m-b-30">
                                <div class="timeline-step">
                                  <div
                                    class={`timeline-l-i ${
                                      data?.status == "دریافت شده" &&
                                      "timeline-l-i--finish"
                                    } `}
                                  >
                                    <span class="timeline-circle"></span>
                                  </div>

                                  <span class="timeline-text">دریافت شده</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {data?.products?.map((product) => {
                            return (
                              <div class="manage-o__description py-4">
                                <div class="description__container">
                                  <div class="description__img-wrap">
                                    <img
                                      class="u-img-fluid"
                                      src={CDN_BASE_URL + product?.img}
                                      alt=""
                                    />
                                  </div>
                                  <div class="description-title">
                                    {product?.name}
                                  </div>
                                </div>
                                <div class="description__info-wrap">
                                  <div>
                                    <span class="manage-o__text-2 u-c-silver">
                                      تعداد:
                                      <span class="manage-o__text-2 u-c-secondary mr-1">
                                        {product?.count}
                                      </span>
                                    </span>
                                  </div>
                                  <div>
                                    <span class="manage-o__text-2 u-c-silver">
                                      قیمت:
                                      <span class="manage-o__text-2 u-c-secondary mr-1">
                                        {product?.price?.toLocaleString()}تومان
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="dash__box dash__box--bg-white dash__box--shadow u-s-m-b-30">
                          <div class="dash__pad-3">
                            <h2 class="dash__h2 u-s-m-y-8">مشخصات ثبت شده</h2>
                            <h2 class="dash__h2 u-s-m-y-8">
                              {data?.userId?.f_name +
                                " " +
                                data?.userId?.l_name}
                            </h2>

                            <span class="dash__text-2 mb-1">
                              {data?.userId?.address}
                            </span>

                            <span class="dash__text-2">
                              {data?.userId?.phone}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OrderDetails;
