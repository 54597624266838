import React from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <Header />
      <div class="app-content">
        <div class="u-s-p-y-90">
          <div class="section__content">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-12 u-s-m-b-30">
                  <div class="empty">
                    <div class="empty__wrap">
                      <span class="empty__big-text">404</span>

                      <span class="empty__text-1">
                        چنین صفحه ای وجود نداره 
                      </span>

                      <Link
                        class="empty__redirect-link btn--e-brand"
                        to="/"
                      >
                      برگشتن به خانه
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
