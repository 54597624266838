import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Reveal from "../motion/Reveal";
import ProductCard from "../common/ProductCard";
import { httpReauest } from "../../utils/httprequest";

const TopProducts = () => {
  const [data, setData] = useState();
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const newData = await httpReauest("GET", "/product/top", {}, {});

    setData(newData.data.data);
  }
  
  return (
    <div style={{ background: "#eee" }} className="u-s-p-b-60 u-s-p-t-60">
      <div className="section__intro u-s-m-b-16">
        <div className="container">
          <div style={{ justifyContent: "center" }} className="row">
            <Reveal>
              <div className="section__text-wrap">
                <h1 className="section__heading u-c-secondary u-s-m-b-12">
                  محصولات ویژه ساراپاشا
                </h1>
              </div>
            </Reveal>
          </div>
        </div>
      </div>

      <div className="section__content">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12">
              <div className="filter__grid-wrapper u-s-m-t-30">
                <div className="row">
                  {data?.map((e) => {
                    return <ProductCard data={e} home/>;
                  })}
                </div>
              </div>
            </div>
            <div style={{ justifyContent: "center" }} className="row col-12">
              <Reveal>
                <Link to={"/products"} className="load-more">
                  <button className="btn btn--e-brand" to="/products">
                    موارد بیشتر
                  </button>
                </Link>
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopProducts;
