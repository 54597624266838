import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import Reveal from "../motion/Reveal";

const Slider = () => {
  return (
    <div class="s-skeleton s-skeleton--h-600 s-skeleton--bg-grey">
      <div class=" primary-style-1" id="hero-slider">
        <Swiper
          spaceBetween={50}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          loop
          autoplay={{ delay: 4000 }}
          modules={[Autoplay]}
        >
          <SwiperSlide>
            <div class="hero-slide hero-slide--1  relative">
              <div className="bg_0"></div>
              <div class="container">
                <div class="row">
                  <div class="w-full">
                    <div class="slider-content slider-content--animation px-6 lg:px-0">
                      <Reveal head width={"fit-content"}>
                        <span
                          style={{ textShadow: "3px 3px 2px var(--brand)" }}
                          class="content-span-2  u-c-white"
                        >
                          سرخ کن بدون روغن
                        </span>
                      </Reveal>
                      <Reveal head width={"fit-content"}>
                        <span class="content-span-3  u-c-white">
                          پیشنهاد ویژه ساراپاشا | تجربه ای باور نکردنی
                        </span>
                      </Reveal>
                      <br />
                      <Reveal head width={"fit-content"}>
                        <Link
                          className="shop-now-link btn--e-brand"
                          to="/signup"
                        >
                          خرید انلاین
                        </Link>
                      </Reveal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div class="hero-slide hero-slide--2  relative">
              <div className="bg_0"></div>
              <div class="container">
                <div class="row">
                  <div class="w-full">
                    <div class="slider-content slider-content--animation px-6 lg:px-0">
                      <Reveal head width={"fit-content"}>
                        <span
                          style={{ textShadow: "3px 3px 2px var(--brand)" }}
                          class="content-span-2  u-c-white"
                        >
                          سرخ کن بدون روغن
                        </span>
                      </Reveal>
                      <Reveal head width={"fit-content"}>
                        <span class="content-span-3  u-c-white">
                          پیشنهاد ویژه ساراپاشا | تجربه ای باور نکردنی
                        </span>
                      </Reveal>
                      <br />
                      <Reveal head width={"fit-content"}>
                        <Link
                          className="shop-now-link btn--e-brand"
                          to="/signup"
                        >
                          خرید انلاین
                        </Link>
                      </Reveal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Slider;
