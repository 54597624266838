import React from 'react';

const RateToStar = ({ number }) => {
  const stars = Array.from({ length: number }, (_, index) => (
    <i className="fa-solid fa-star text-yellow-500 mx-[1px]"></i>
  ));

  return <div>{stars}</div>;
};

export default RateToStar;