import React from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";

const Checkout = () => {
  return (
    <div>
      <Header />
      
      <Footer />
    </div>
  );
};

export default Checkout;
