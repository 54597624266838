import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import ImageGallery from "react-image-gallery";
import Reveal from "../motion/Reveal";
import { CDN_BASE_URL } from "../../config";

const ProductSlider = ({ data }) => {
  const images = data?.map((src) => {
    return {
      original: CDN_BASE_URL + src,
      thumbnail: CDN_BASE_URL + src,
    };
  })


  return (
    <div dir="ltr" class=" pd-wrap">
      <Reveal head>
        <div id="pd-o-initiate">
          <ImageGallery
            items={images ? images : []}
            renderLeftNav={(onClick, disabled) => (
              <i
                className="fa-solid fa-angle-left absolute top-[48%] bg-[--brand] w-[32px] h-[32px] flex pr-[1px] justify-center items-center text-[18px] m-0 text-white rounded-full left-2 z-10"
                onClick={onClick}
              />
            )}
            renderRightNav={(onClick, disabled) => (
              <i
                className="fa-solid fa-angle-right absolute top-[48%] bg-[--brand] w-[32px] h-[32px] flex pl-[1px] justify-center items-center text-[18px] m-0 text-white rounded-full right-2 z-10"
                onClick={onClick}
              />
            )}
          />
        </div>
      </Reveal>
    </div>
  );
};

export default ProductSlider;
