import React from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Reveal from "../motion/Reveal";

const Reviews = () => {
  return (
    <div style={{background :"#eee"}} class="u-s-p-y-80 ">
      <div class="section__intro u-s-m-b-46">
        <div class="container">
          <div class="row row--center">
            <Reveal>
              <div class="col-lg-12">
                <div class="section__text-wrap">
                  <h1 class="section__heading u-c-secondary u-s-m-b-12">
                    نظرات مشتریان
                  </h1>
                </div>
              </div>
            </Reveal>
          </div>
        </div>
      </div>

      <div class="section__content">
        <div class="container">
          <div class="">
            <div class="" id="testimonial-slider">
              <Swiper
                spaceBetween={50}
                loop
                autoplay={{ delay: 4000 }}
                modules={[Autoplay]}
              >
                <SwiperSlide>
                  <Reveal>
                    <div class="testimonial bg-white p-4 rounded-[18px]">
                      <div class="testimonial__img-wrap">
                        <img
                          class="testimonial__img"
                          src="images/about/test-1.jpg"
                          alt=""
                        />
                      </div>
                      <div class="testimonial__content-wrap">
                        <span class="testimonial__double-quote">
                          <i class="fa-solid fa-quote-right"></i>
                        </span>
                        <blockquote class="testimonial__block-quote">
                          <p>
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها
                            و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                            لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و
                            کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می
                            باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و
                            آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا
                            با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای
                            علی الخ
                          </p>
                        </blockquote>

                        <span class="testimonial__author">
                          John D. / DVNTR Inc.
                        </span>
                      </div>
                    </div>
                  </Reveal>
                </SwiperSlide>
                <SwiperSlide>
                  <Reveal>
                    <div class="testimonial bg-white p-4 rounded-[18px]">
                      <div class="testimonial__img-wrap">
                        <img
                          class="testimonial__img"
                          src="images/about/test-1.jpg"
                          alt=""
                        />
                      </div>
                      <div class="testimonial__content-wrap">
                        <span class="testimonial__double-quote">
                          <i class="fa-solid fa-quote-right"></i>
                        </span>
                        <blockquote class="testimonial__block-quote">
                          <p>
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها
                            و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                            لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و
                            کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می
                            باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و
                            آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا
                            با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای
                            علی الخ
                          </p>
                        </blockquote>

                        <span class="testimonial__author">
                          John D. / DVNTR Inc.
                        </span>
                      </div>
                    </div>
                  </Reveal>
                </SwiperSlide>
                <SwiperSlide>
                  <Reveal>
                    <div class="testimonial bg-white p-4 rounded-[18px]">
                      <div class="testimonial__img-wrap">
                        <img
                          class="testimonial__img"
                          src="images/about/test-1.jpg"
                          alt=""
                        />
                      </div>
                      <div class="testimonial__content-wrap">
                        <span class="testimonial__double-quote">
                          <i class="fa-solid fa-quote-right"></i>
                        </span>
                        <blockquote class="testimonial__block-quote">
                          <p>
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها
                            و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                            لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و
                            کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می
                            باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و
                            آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا
                            با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای
                            علی الخ
                          </p>
                        </blockquote>

                        <span class="testimonial__author">
                          John D. / DVNTR Inc.
                        </span>
                      </div>
                    </div>
                  </Reveal>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
